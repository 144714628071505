$lightneutral: rgb(202 189 184 / 100%);
$darkneutral: rgb(71 67 74 / 100%);
$accentterracotta: rgb(158 91 64 / 100%);
$accentterracotta75: rgb(158 91 64 / 75%);
$greenmain: rgb(69 125 122 / 100%);
$purplecomplimentary: rgb(117 76 120 / 100%);
$textshadow: drop-shadow(0.1rem 0.1rem 0.2rem $darkneutral);

$primary: $purplecomplimentary;
$secondary: $accentterracotta;
$success: $greenmain;

/* Importing bootstrap completely, so we don't need to re-import from anywhere else
boostrap scss kept in a separate directory from scss so it isn't converted to css */
@import "~bootstrap/scss/bootstrap.scss";

$image-path: "~/src/images" !default;
$font-path: "~/src/fonts" !default;

@font-face {
  font-family: "Martel_Bold";
  src: url("#{$font-path}/Martel/Martel-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: Martel;
  src: url("#{$font-path}/Martel/Martel-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Martel_Light";
  src: url("#{$font-path}/Martel/Martel-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: light;
}

@font-face {
  font-family: Yeseva;
  src: url("#{$font-path}/Yeseva_One/YesevaOne-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Cormorant_Garamond";
  src: url("#{$font-path}/Cormorant_Garamond/CormorantGaramond-SemiBoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

.yeseva {
  font-family: Yeseva, cursive;
  font-weight: 400;
}

.martel {
  font-family: Martel, serif;
  font-weight: 400;
}

.martel-bold {
  font-family: "Martel_Bold", serif;
  font-weight: 800;
}

.cormorant {
  font-family: "Cormorant_Garamond", serif;
  font-style: italic;
  font-weight: 600;
}

/* https://css-tricks.com/transitions-only-after-page-load/ */
.preload * {
  transition: none !important;
}

body {
  background-color: $lightneutral;
  color: $darkneutral;
  line-height: 160%;
  size: 20px;

  @extend .martel;

  img {
    max-width: 100% !important;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @extend .yeseva;
  }

  h2 {
    margin-bottom: 1rem !important;
  }

  nav.navbar {
    a.nav-link,
    a.navbar-brand {
      @extend .martel-bold;

      transition: color 1.2s;
    }

    transition:
      color 1.2s,
      background-color 1.2s;
    background-color: white;

    &.navbar-homepage {
      background-color: rgb(0 0 0 / 0%);
    }

    @extend .navbar-light;
    @extend .text-dark;

    @include media-breakpoint-up(md) {
      background-color: rgb(0 0 0 / 0%);
    }
  }

  &:not(.header-not-at-top) {
    nav.navbar.navbar-homepage {
      @extend .navbar-dark;
    }
  }

  &.header-not-at-top {
    nav.navbar {
      @extend .navbar-light;
      @extend .text-dark;
      @extend .bg-light;
    }
  }

  a.btn {
    padding: 1.5rem 4.5rem;
    border-radius: 6px;
    margin-top: 1rem;
  }

  /* Padding for nav */
  padding-top: 4.5rem;

  .down-arrow a {
    border-radius: 50%;
    padding: 0.5rem;
    z-index: 1000;
    position: relative;

    img {
      width: 3rem;
    }
  }

  #socials {
    position: absolute;
    right: 0;
    top: 30%;
    padding: 10px;
    border-radius: 10px 0 0 10px;
    background-color: rgb(0 0 0 / 25%);

    img {
      filter: grayscale(1) invert(1);

      &:hover {
        filter: grayscale(1) invert(0.75);
      }
    }
  }

  .swoosh {
    width: 100%;
    position: relative;

    &.swoosh-light {
      background-size: 100% 50px;
      margin-top: -50px;
      height: 50px;
      background-image: url("#{$image-path}/curveneutral.svg#svgView(preserveAspectRatio(none))");

      @include media-breakpoint-up(md) {
        background-size: 100% 100px;
        margin-top: -100px;
        height: 100px;
      }
    }

    &.swoosh-purple {
      background-image: url("#{$image-path}/curvepurple.svg#svgView(preserveAspectRatio(none))");
      background-size: 100% 50px;
      margin-top: -50px;
      height: 50px;
    }
  }

  #cover-image {
    z-index: -100;
    top: 0;
  }

  img#hero-image {
    object-fit: cover;
    object-position: 47% top;

    @include media-breakpoint-up(md) {
      object-position: 78% top;
    }

    @include media-breakpoint-up(lg) {
      object-position: 100% top;
    }
  }

  section#hero {
    height: calc(100vh - 4.5rem);

    /* img.signature-image {
      filter: grayscale(1) invert(1) drop-shadow(2px 2px 2px #fff);

      @include media-breakpoint-up(md) {
        filter: none;
      }
    } */

    .bkg-brush {
      background-image: url("#{$image-path}/purplebrushstroke_sm.png");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      min-height: 200px;
      margin: -4rem 1rem;
      padding: 3rem;

      @include media-breakpoint-up(md) {
        margin: -5rem 1rem;
        padding: 4rem;
      }

      span {
        width: min-content;
      }

      .strapline {
        font-size: 30px;
        line-height: 110%;
        font-family: "Martel_Light", serif;

        @include media-breakpoint-up(md) {
          font-size: 36px;
        }

        @include media-breakpoint-up(lg) {
          font-size: 40px;
        }
      }
    }

    .hero-content {
      img {
        max-width: 100%;
      }
    }
  }

  .watermark {
    background-color: $lightneutral;

    @include media-breakpoint-up(md) {
      background-image: url("#{$image-path}/watercolor_stain.png");
      background-size: 100%;
      background-position: 300px 200px;
      background-repeat: no-repeat;
    }
  }

  .contact-details-block {
    background-color: $darkneutral;
  }

  .text-and-image.container-fluid {
    background-color: $accentterracotta75;
    background-size: cover;

    blockquote {
      @extend .cormorant;

      font-size: 32px;
      line-height: 150%;
      filter: $textshadow;

      @include media-breakpoint-up(md) {
        font-size: 48px;
      }
    }

    .quotesource {
      font-size: 18px;
      filter: $textshadow;
    }

    &.cta-container {
      background-color: $accentterracotta;
    }
  }

  .row.text-image-alternating:nth-of-type(odd) {
    .col.text {
      @extend .order-md-first;
    }

    .col.image {
      @extend .order-md-last;
    }
  }
}

#top-of-site-pixel-anchor {
  position: absolute;
  width: 1px;
  height: 1px;
  top: 200px;
  left: 0;
}

.ms-n5-override {
  @include media-breakpoint-up(md) {
    margin-left: -$spacer * 3;
  }
}

/* Hide cookies tab on mobiles */
.cc-revoke.cc-bottom:not(.cc-animate) {
  bottom: -32px !important;
}
