form {
  /* FORM */
  .field-row {
    input,
    textarea {
      @extend .form-control;
    }

    p.help-text {
      @extend .form-text;
      @extend .text-muted;
    }

    ul.errorlist {
      color: $danger;
      padding-left: 3px;

      li {
        list-style-type: none;
      }
    }
  }
}
