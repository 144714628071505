/*
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  font-family: Roboto, sans-serif;
}
*/

/* disabling stylelint because this is mostly directly imported from puput */
/* stylelint-disable */

.social-item {
  display: inline-block;
  width: 25px;
  height: 25px;
  background: #ededed;
  border-radius: 50%;
  border-radius: 50%;
  border-radius: 50%;

  i {
    margin-top: 5px;
    margin-left: 25%
  }
}

ul.sidebar {
  list-style: none;
  padding-left: 1rem;

  ul {
    list-style: none;
  }

  ul.meta {
    margin-top: -0.4rem;
  }
}

.blog-preview {
  background-color: $purplecomplimentary;

  @extend .text-light;

  .btn {
    padding: 0.5rem 1.5rem;
  }

  img {
    @extend .d-none;
  }
}

.blog-article-full {
  @extend .text-light;

  background-color: $accentterracotta75;

  h2.post_title {
    background-color: $accentterracotta;
  }
}

.blog-preview a,
.blog-article-full a {
  @extend .text-light
}

.socials i {
  color: $success;
}


/* ul.links {
  margin: 0 0 25px;
  border-bottom: 0;
  padding: 5px 0;
}

ul.links li {
  font-size: 12px;
  margin-right: 13px;
  font-weight: 300;
  color: #666;
  display: inline-block;
}

ul.links li i {
  color: var(--main-color);
}

ul.links li a {
  color: #666;
  font-weight: 300;
}

ul.links li ul {
  display: inline;
  padding: 0;
} */

/* ul.sidebar {
  border-top: 2px solid var(--main-color);
  background-color: #ededed;
  padding: 20px;
  margin-bottom: 50px;
  list-style: none;
}

ul.sidebar h4 {
  font-size: 16px;
  font-weight: 500;
  color: #333;
  margin-bottom: 20px;
}

ul.sidebar li {
  margin: 0 4px 10px 0;
  list-style: none;
}

ul.sidebar li.tag {
  display: inline-block;
  background-color: var(--main-color);
  padding: 3px 10px;
}

ul.sidebar li.tag a {
  color: #fff;
  font-size: 12px;
}

ul.sidebar li a {
  font-size: 14px;
  font-weight: 300;
  color: #666;
}

ul.sidebar ul li i {
  color: var(--main-color);
}

ul.sidebar li>ul {
  padding-left: 0;
}

ul.sidebar ul li>ul li {
  padding-left: 20px;
} */

/* a:hover {
  color: var(--main-color);
} */

.blog_btn {
  border-radius: 0;
  font-weight: 300;
}

.blog_btn.continue,
.blog_btn.back {
  display: block;
  text-align: right;
  background-color: transparent;
  color: var(--main-color);
  padding: 10px 0;
}

.blog_btn.back {
  text-align: left;
}

.blog_btn.continue:hover,
.blog_btn.continue:focus,
.blog_btn.back:hover,
.blog_btn.back:focus {
  text-decoration: none;
}

.blog_btn.continue:active,
.blog_btn.back:active {
  outline: inherit;
}

/* .page-content h2.post_title {
  text-transform: uppercase;
  font-size: 22px;
} */

/* .page-content h2.post_title a::before {
  font-family: FontAwesome;
  content: "\f02e";
  color: var(--main-color);
  font-size: 17px;
  margin-right: 9px;
} */

/* .page-content h2.post_title a:hover {
  text-decoration: none;
  color: #666;
} */

.page-content .social-share-all {
  margin-bottom: 25px;
}

.page-content .social-share-all li.social-item {
  display: inline-block;
  width: 25px;
  height: 25px;
  background: #ededed;
  margin-right: 3px;
  margin-top: 5px;
  border-radius: 50%;
  border-radius: 50%;
  border-radius: 50%;
}

.page-content .social-share-all li.social-item i {
  color: #fff;
  text-align: center;
  width: 100%;
  margin-top: 6px;
}

.page-content .social-share-all li.social-item:hover {
  background-color: #333;
}

.page-content .social-share-all::before {
  background: #ededed;
  display: block;
  height: 1px;
  width: 78%;
  position: absolute;
  top: 19px;
  left: 7px;
  content: "";
  text-indent: -9999em;
}

/* h2 a {
  color: #555;
}

h2 a:hover {
  color: var(--main-color);
  text-decoration: none;
}

h3 {
  font-size: 20px;
}

p {
  font-weight: 300;
  color: #666;
} */

.responsive-object {
  padding-bottom: 10px !important;
}

.about {
  background: var(--main-color) none repeat scroll 0 0;
  margin-bottom: 4em;
}

.about .title-section {
  padding: 30px 0 20px;
}

.about .title-section-image {
  padding: 0;
}

/* .about .title-section h1 {
  font-weight: 400;
} */

/* .about .title-section-image h1 {
  margin-top: 0;
  font-weight: 400;
  font-size: 30px;
} */

/* .about .title-section h2 {
  color: #ededed;
}

.about .title-header a {
  color: #fff;
}

.about .title-header a:hover,
.about .title-header a:focus {
  color: #fff;
  text-decoration: none;
} */

.blog_sidebar {
  padding: 5px 20px 0 45px;
}

.blog_sidebar ul>ul {
  padding: 0;
}

.blog_sidebar form.search_form {
  margin-bottom: 30px;
}

.blog_sidebar form.search_form input[type="search"] {
  width: 100%;
  /* border: 1px solid #f0f0f0; */
  padding: 6px 10px;
}

.blog_sidebar .input-group-btn {
  background: var(--main-color);
  border-radius: 0 5px 5px 0;
  border-radius: 0 5px 5px 0;
  border-radius: 0 5px 5px 0;
}

.blog_sidebar .input-group-btn .btn.btn-default {
  border-color: transparent;
  background-color: transparent;
}

.blog_sidebar .input-group-btn .btn.btn-default i {
  color: #fff;
}

.blog_sidebar .rss-sitemap {
  margin-bottom: 15px;
  text-align: right;
}

.blog_sidebar .rss-sitemap a {
  color: #999;
  font-size: 12px;
  text-decoration: none;
}

.blog_sidebar .rss-sitemap i {
  color: var(--main-color);
}

.blog_sidebar .rss-sitemap span {
  font-size: 12px;
  color: #999;
}

.post-summary {
  margin-bottom: 20px;
}

.post-summary ul {
  padding: 0;
}

.post-summary ul li {
  margin-bottom: 0;
}

.relatedPosts {
  margin: 50px 0 100px;
}

.relatedPosts img {
  width: 100%;
  height: auto;
  margin-bottom: 15px;
}

/* RIGHT SIDE */
.lateralPosts h4 {
  font-weight: 300;
  margin: 0;
}

.lateralPosts .boxCircle {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border-radius: 50%;
  border-radius: 50%;
}

.lateralPosts li {
  font-size: 0.7em;
  display: inline-block;
  color: var(--main-color);
}

.searchTerm {
  margin: 10px 0 30px;
  padding: 20px;
  background-color: #ededed;
  color: #666;
}

/* .page-content,
.blog_grid {
  position: relative;
  border-bottom: 0;
  padding: 10px 20px 20px;
  background: #f8f8f8;
  margin-bottom: 20px;
}

.blog_grid p {
  color: #666;
  font-size: 1em;
  line-height: 1.5em;
  margin-top: 1em;
}

.blog_grid h2 {
  text-align: center;
  margin-top: 10px;
}

.blog_grid h2 a::after {
  content: "";
  width: 25px;
  height: 4px;
  display: block;
  margin: 0 auto;
  background-color: var(--main-color);
  margin-top: 7px;
}

.blog_grid .article .social-share li {
  float: left;
}

.blog_grid .article .social-share li.social-item {
  display: inline-block;
  width: 25px;
  height: 25px;
  background: #ededed;
  margin-top: 5px;
  margin-right: 3px;
  border-radius: 50%;
  border-radius: 50%;
  border-radius: 50%;
}

.blog_grid .article .social-share li.social-item i {
  color: #fff;
  text-align: center;
  width: 100%;
  margin-top: 6px;
}

.blog_grid .article .social-share li.social-item:hover {
  background-color: #333;
}

.blog_grid .article .social-share::after {
  background: #ededed;
  display: block;
  height: 1px;
  width: 78%;
  position: absolute;
  top: 19px;
  right: 0;
  content: "";
  text-indent: -9999em;
} */

.rich-text {
  margin-bottom: 5%;
  margin-top: 10px;
}

.rich-text h3 {
  margin-bottom: 10px;
}

.richtext-image {

  &.full-width {
    @extend .my-2;
    width: 100%;
    height: auto;
    max-height: 700px;
    object-fit: cover;
  }

  &.left,
  &.right {
    @extend .mb-2;
    width: 100%;
    height: auto;

    @include media-breakpoint-up(md) {
      width: 45%
    }
  }

  &.left {
    @extend .me-2;
    @extend .float-start;
  }

  &.right {
    @extend .ms-2;
    @extend .float-end;
  }
}

.itemPosts {
  margin-top: 10px;
  padding-top: 15px;
  border-top: 1px dotted var(--main-color);
}

.itemPosts h3 {
  font-size: 16px;
  margin-bottom: 5px;
}

.itemPosts p {
  font-size: 15px;
}

.itemPosts .box.feature a {
  color: var(--main-color);
}

.pagination {
  width: 100%;
  margin: 30px 0;
  text-align: center;
}

.pagination .endless_separator {
  background: transparent;
  color: var(--main-color);
  font-size: 18px;
}

.pagination span.endless_page_current,
.pagination .endless_page_link {
  padding: 7px 11px;
  color: var(--main-color);
}

.pagination .endless_page_current:active,
.pagination .endless_page_link:active {
  background: var(--main-color);
}

.pagination span {
  background: var(--main-color);
}

.pagination span strong {
  color: #fff;
}

.footer_bottom {
  background-color: var(--main-color);
  padding: 20px 40px;
}

.footer_bottom .copy p {
  text-align: center;
  color: #d1d1d1;
}

.footer_bottom .copy p a {
  color: #fff;
}

/* Menu */
.menu li {
  margin: 0 0 3px;
}

.menu ul {
  padding-left: 0;
}

.menu li a {
  background-color: #f2f2f2;
  background-color: rgb(0 0 0 / 5%);
  color: #1e1e1e;
  display: block;
  padding: 9px 9px 9px 60px;
  position: relative;
  text-decoration: none;
  transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.menu li a::before {
  background: #e1e1e1;
  background: rgb(255 255 255 / 40%);
  bottom: 0;
  content: "";
  display: block;
  left: 45px;
  position: absolute;
  top: 0;
  width: 1px;
}

.menu li a:hover {
  background-color: #e9e9e9;
  background-color: rgb(0 0 0 / 10%);
  transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.menu li.active>a {
  background-color: var(--main-color);
  color: #fff;
  transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.menu li.active>a::before {
  background: #444;
  background: rgb(255 255 255 / 20%);
}

.menu li.parent>a .open-sub {
  bottom: 0;
  display: block;
  left: 0;
  position: absolute;
  top: 0;
  width: 45px;
}

.menu li.parent>a .open-sub::before,
.menu li.parent>a .open-sub::after {
  background: var(--main-color);
  content: "";
  display: block;
  height: 2px;
  left: 0;
  margin: 19px 0 0 15px;
  position: absolute;
  top: 0;
  width: 16px;
}

.menu li.parent>a .open-sub::after {
  height: 16px;
  margin: 12px 0 0 22px;
  width: 2px;
}

.menu li.parent.active>a .open-sub::before,
.menu li.parent.active>a .open-sub::after {
  background: #fff;
}

.menu li.parent.active>a .open-sub::after {
  display: none;
}

.menu .sub {
  background-color: #f2f2f2;
  background-color: rgb(0 0 0 / 5%);
  padding: 7px 0 4px 5px;
}

.menu .sub li {
  margin: 0;
}

.menu .sub a {
  background: none;
  color: #7f7f7f;
  margin: 0;
  padding: 4px 9px 4px 60px;
}

.menu .sub a::before {
  display: none;
}

.menu .sub a:hover {
  background-color: #e9e9e9;
  background-color: rgb(0 0 0 / 5%);
}

.menu .sub .active>a {
  background: none;
  color: #000;
}

.menu .sub .sub {
  background: none;
}

.menu .sub li.parent>a .open-sub {
  left: 10px;
}

.menu .sub li.parent>a .open-sub::before,
.menu .sub li.parent>a .open-sub::after {
  background: #7f7f7f;
  margin: 14px 0 0 22px;
  width: 12px;
}

.menu .sub li.parent>a .open-sub::after {
  height: 12px;
  margin: 9px 0 0 27px;
  width: 2px;
}

.menu .sub li {
  padding: 0;
}

.menu li>a .item-icon {
  left: 15px;
  position: absolute;
  top: 13px;
}

.menu li:not(.active)>a .item-icon {
  opacity: 0.5;
  filter: alpha(opacity=50);
}

@media (max-width: 991px) {
  .blog_sidebar {
    padding: 5px 5px 0 20px;
  }
}

@media (max-width: 768px) {
  .blog_sidebar {
    padding: 5px 10px 0;
  }
}