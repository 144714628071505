@import "react-alice-carousel/lib/alice-carousel.css";
@import "cookieconsent/src/styles/base.css";
@import "cookieconsent/src/styles/layout.css";
@import "cookieconsent/src/styles/media.css";
@import "cookieconsent/src/styles/animation.css";
@import "cookieconsent/src/styles/themes/classic.css";
@import "cookieconsent/src/styles/themes/edgeless.css";
@import "./qjstyles.scss";
@import "./form_custom.scss";
@import "./puput_custom.scss";

$enable-negative-margins: true;
